// import { API_SERVICE_REQUESTS, POST_DATA } from "@/core/store/crud.module";
// import store from "@/core/store/index";
import dateFormatter from "@/core/helpers/dateFormatter";

function getAssignedTo(assignedTo) {
    return assignedTo?.fullName;
  }
function getCreatedBy(createdBy) {
    return createdBy?.fullName;
  }

  function formatDate(date) {
    return dateFormatter.formatEditDate(date);
  }

export default {
  async printRequests(requestsToPrint) {
    let pdfMake = require("pdfmake/build/pdfmake.js");
    if (pdfMake.vfs == undefined) {
      var pdfFonts = require("pdfmake/build/vfs_fonts.js");
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }

    let self = this;


    pdfMake.fonts = {
      Fontello: {
        normal: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
        bold: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
        italics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf",
        bolditalics: self.baseUrl + "/media/fontello-bcdeccb8/font/fontello.ttf"
      },

      //download default Roboto font from cdnjs.com
      Roboto: {
        normal:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics:
          "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf"
      }
    };

    let reportBody = [];
    let itemCount = 0;

    requestsToPrint.forEach(function(item) {
      itemCount += 1;
      if (itemCount > 1) {
        reportBody.push({
          text: "",
          fontSize: 14,
          bold: false,
          pageBreak: "before",
          margin: [0, 0, 0, 0]
        });
      }
      let row = [];

      let mainTableBody = [];
      row.push({
        text: "#" + item.id,
        fontSize: 18,
        bold: true,
        pageBreak: "",
        margin: [0, 0, 0, 8]
      });

      mainTableBody.push(row);

      let requestDetails = [];
      requestDetails.push({
        text: item.requestType?.name,
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 8]
      });

      requestDetails.push({
        margin: [8, 0, 0, 8],
        table: {
          body: [
            [
              { text: "Community:", bold: true, fontSize: 10 },
              { text: item.communityName, bold: false, fontSize: 10 },
              { text: "Location:", bold: true, fontSize: 10 },
              { text: item.location, bold: false, fontSize: 10 }
            ],
            [
              { text: "Opened By:", bold: true, fontSize: 10 },
              {
                text: getCreatedBy(item.createdBy),
                bold: false,
                fontSize: 10
              },
              { text: "Opened:", bold: true, fontSize: 10 },
              {
                text: formatDate(item.created),
                bold: false,
                fontSize: 10
              }
            ],
            [
              { text: "Assigned To:", bold: true, fontSize: 10 },
              {
                text: getAssignedTo(item.assignedTo),
                bold: false,
                fontSize: 10
              },
              { text: "Due Date:", bold: true, fontSize: 10 },
              {
                text: formatDate(item.targetCompletionDate),
                bold: false,
                fontSize: 10
              }
            ],
            [
              { text: "Contact Name:", bold: true, fontSize: 10 },
              { text: item.contactName, bold: false, fontSize: 10 },
              { text: "Contact Phone:", bold: true, fontSize: 10 },
              { text: item.contactPhone, bold: false, fontSize: 10 }
            ],
            [
              { text: "Contact Email:", bold: true, fontSize: 10 },
              { text: item.contactEmail, bold: false, fontSize: 10 },
              "",
              ""
            ]
          ]
        },
        layout: "noBorders"
      });

      requestDetails.push({
        text: "Description:",
        bold: true,
        fontSize: 10,
        margin: [8, 0, 0, 0]
      });
      requestDetails.push({
        text: item.description,
        bold: false,
        fontSize: 10,
        margin: [16, 0, 0, 8]
      });
      if (item.comments.$values.length > 0) {
        requestDetails.push({
          text: "Comments",
          fontSize: 10,
          bold: true,
          margin: [8, 0, 0, 8]
        });

        item.comments.$values.forEach(function(comment) {
          requestDetails.push({
            text:
              getCreatedBy(comment.createdBy) +
              " on " +
              formatDate(comment.created),
            bold: true,
            fontSize: 10,
            margin: [16, 0, 0, 0]
          });
          requestDetails.push({
            text: comment.description,
            bold: false,
            fontSize: 10,
            margin: [16, 0, 0, 8]
          });
        });
      }

      row = [];
      row.push(requestDetails);

      mainTableBody.push(row);

      reportBody.push({
        layout: "noBorders",
        table: {
          headerRows: 1,
          body: mainTableBody
        }
      });
    });

    let docDefinition = {
      images: {
        ynLogo: self.baseUrl + "/media/logos/yardnique-logo.png"
      },
      pageSize: "LETTER",
      pageMargins: [20, 20, 20, 20],
      content: reportBody,
      defaultStyle: {
        fontSize: 12,
        bold: false
      },
      styles: {
        heading: {
          color: "#E06521",
          bold: true,
          margin: [0, 0, 0, 5]
        },
        icon: { font: "Fontello" },

        mt5: { margin: [0, 5, 0, 0] },
        mt10: { margin: [0, 10, 0, 0] },
        mb5: { margin: [0, 0, 0, 5] },
        mb10: { margin: [0, 0, 0, 10] },
        my5: { margin: [0, 5, 0, 5] },
        my10: { margin: [0, 10, 0, 10] }
      }
    };

    pdfMake.createPdf(docDefinition).open();
  }
};
